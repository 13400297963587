import React, { Component } from "react";
import axios from 'axios'

import "./style.css";
import Lottie from "react-lottie-player";
import formSubmit from '../../images/Submit button.json'
import Select from "react-select";
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils.js'
import {withRouter} from "react-router-dom";
import {Translation, withTranslation} from "react-i18next";
import i18n from "../i18n/i18n";


class Form extends Component {
    state = {
        iti: {},
        name: "",
        email: "",
        phone: "",
        experience: "",
        age:"",
        errors: {},
        disabledBtn: false,
        successSubmit: false
    }

    async componentDidMount() {
        const input = document.querySelector("#phone");
        let iti = intlTelInput(input, {
            autoPlaceholder: "on",
            nationalMode: true,
            initialCountry: "eg",
            excludeCountries: ["il"],
            separateDialCode: true,
            preferredCountries: ["eg"],
            utilsScript: "intl-tel-input/build/js/utils.js",

        });
        this.setState({input: input, iti: iti})
    }

    handleChangeGeneralInputs = (event, name) => {
        this.setState({ [event.target.name]: event.target.value.replace(/\s\s/g, '').replace(/\.+/g, '.') }, () => {
            let errors = {};
            let formIsValid = true;
            if (name === "name") {
                if (!this.state.name) {
                    formIsValid = false;
                    errors.name =<Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t('Full name cannot be empty')}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else if (this.state.name.length < 3) {
                    formIsValid = false;
                    errors.name = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t('Full name cannot be less than 3 char')}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else if (typeof this.state.name !== "undefined") {
                    if (!this.state.name.match(/^[A-Za-z-أ-يa\s]+$/gi)) {
                        formIsValid = false;
                        errors.name = <Translation i18n={i18n}>
                            {
                                (t, {i18n}) => <p>{t('Full name cannot be empty and must be letters only')}</p> // will be looked up from namespace ns1
                            }
                        </Translation>
                    }
                } else {
                    errors.name = ""
                    formIsValid = true;

                }
            }
            if (name === "email") {
                if (!this.state.email) {
                    formIsValid = false;

                    errors.email = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t('Email cannot be empty')}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else if (typeof this.state.email !== "undefined") {
                    if (
                        !this.state.email.match(
                            /^(?![^@]*([-._])\1)(?![^@]*([-.])(?:[^@]*\2){2})\w(?:[\w.-]*\w)?@(?:[a-zA-Z]+\.)+[a-zA-Z]+$/
                        )
                    ) {
                        formIsValid = false;
                        errors.email =<Translation i18n={i18n}>
                            {
                                (t, {i18n}) => <p>{t('Please enter valid Email')}</p> // will be looked up from namespace ns1
                            }
                        </Translation>
                    }
                } else {
                    errors.email = ("")
                    formIsValid = true
                }
            }
            if (name === "phone") {
                if (!this.state.phone) {
                    formIsValid = false;

                    errors.phone =<Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t('Phone number cannot be empty')}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else if (this.state.phone.length < 10) {
                    formIsValid = false;
                    errors.phone = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t('Phone number cannot be less than 10 digit')}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                }
                else if (typeof this.state.phone !== "undefined") {
                    if (!this.state.phone.match(/^[0-9]+$/gi)) {
                        formIsValid = false;
                        errors.phone = <Translation i18n={i18n}>
                            {
                                (t, {i18n}) => <p>{t('Phone number cannot contain letters')}</p> // will be looked up from namespace ns1
                            }
                        </Translation>
                    }
                }
                else {
                    errors.phone = ("")
                    formIsValid = true
                }
            }
            this.setState({ errors: errors });
            return formIsValid;
        })
    }

    handleSelect = (e, name) => {
        this.setState({ [name]: e.value }, () => {
            let errors = {};
            let formIsValid = true;
            if (name === "experience") {
                if (!this.state.experience) {
                    formIsValid = false;
                    errors.experience =  <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t('Please select your professional experience')}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else {
                    errors.experience = ""
                    formIsValid = true;
                }
            }

            this.setState({ errors: errors });
            return formIsValid;

        });

    }
    handleSelect2 = (ee, namee) => {
        this.setState({ [namee]: ee.value }, () => {
            let errors = {};
            let formIsValid = true;
            if (namee === "age") {
                if (!this.state.age) {
                    formIsValid = false;
                    errors.age =  <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t('Please select your Age')}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else {
                    errors.age = ""
                    formIsValid = true;
                }
            }


            this.setState({ errors: errors });
            return formIsValid;

        });

    }

    handleSubmit = () => {
        if (this.handleValidation() === false) {
        } else {
            this.setState({ disabledBtn: true })
            const newparams = {
                url: "api/resource/VOLSME",
                data: {
                    name1: this.state.name,
                    email: this.state.email,
                    phonenumber: !this.state.phone.includes('+') ? '+' + this.state.iti.activeItem.dataset.dialCode + this.state.phone : this.state.phone,
                    experience: this.state.experience,
                    age:this.state.age,
                    session:this.props.match.params.id
                }
            };
            axios.post(process.env.REACT_APP_API_URL, newparams)
                .then((response) => {
                    let res = response.status;

                    if (res === 200) {
                        this.setState({ successSubmit: true, disabledBtn: false })
                        this.setState({
                            name: "",
                            email: "",
                            phone: "",
                            experience: "",
                            age:""
                        });
                        setTimeout(() => {

                            this.setState({ successSubmit: false },()=>{
                                const input = document.querySelector("#phone");
                                let iti = intlTelInput(input, {
                                    autoPlaceholder: "on",
                                    nationalMode: true,
                                    initialCountry: "eg",
                                    excludeCountries: ["il"],
                                    separateDialCode: true,
                                    preferredCountries: ["eg"],
                                    utilsScript: "intl-tel-input/build/js/utils.js",

                                });
                                this.setState({input: input, iti: iti})
                            })
                        }, 10000);
                    }
                }).catch((error) => {
                    this.setState({ disabledBtn: false })
                })
        }
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value.replace(/[^0-9]+/ig, '')}, () => {
            if (!this.state.phone) {
                this.setState({
                    phone_error:  <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t('field is required')}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                })
            } else {
                this.setState({
                    phone_error: ''

                })
            }
            let input = this.state.input
            let iti = this.state.iti
            let errorMsg = document.querySelector("#error-msg"),
                validMsg = document.querySelector("#valid-msg")
            let errorMap = [this.props.t('Invalid number'), this.props.t('Invalid country code'), this.props.t('Too short'), this.props.t('Too long'), this.props.t('Invalid number')];
            let reset = function () {
                input.classList.remove("error");
                errorMsg.innerHTML = "";
                errorMsg.classList.add("hide");
                validMsg.classList.add("hide");
            };

            reset();

            if (input.value.trim()) {
                if (iti.isValidNumber()) {
                    validMsg.classList.remove("hide");
                    this.setState({disabled: false})
                } else {
                    this.setState({disabled: true})
                    input.classList.add("error");
                    let errorCode = iti.getValidationError();
                    errorMsg.innerHTML = errorMap[errorCode];
                    errorMsg.classList.remove("hide");
                }
            }

        });

    };

    handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.experience) {
            formIsValid = false;
            errors.experience =  <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t('Please select your professional experience')}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }
        if (!this.state.age) {
            formIsValid = false;
            errors.age =  <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t('Please select your Age')}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }

        if (!this.state.name) {
            formIsValid = false;
            errors.name = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t('Full name cannot be empty')}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }
        if (this.state.name.length < 3) {
            formIsValid = false;
            errors.name = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t('Full name cannot be less than 3 char')}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }

        if (typeof this.state.name !== "undefined") {
            if (!this.state.name.match(/^[A-Za-z-أ-يa\s]+$/gi)) {
                formIsValid = false;
                errors.name =  <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t('Full name cannot be empty and must be letters only')}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            }
        }
        if (!this.state.email) {
            formIsValid = false;

            errors.email = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t('Email cannot be empty')}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }

        if (typeof this.state.email !== "undefined") {
            if (
                !this.state.email.match(
                    /^(?![^@]*([-._])\1)(?![^@]*([-.])(?:[^@]*\2){2})\w(?:[\w.-]*\w)?@(?:[a-zA-Z]+\.)+[a-zA-Z]+$/
                )
            ) {
                formIsValid = false;
                errors.email =<Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t('Please enter valid Email')}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            }
        }

        if (!this.state.phone) {
            formIsValid = false;
            this.setState({phone_error:<Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t('Phone number cannot be empty')}</p> // will be looked up from namespace ns1
                    }
                </Translation>})
        }
        this.setState({ errors: errors })
        return formIsValid;
    }

    render() {
        let {t} = this.props
        const options = [
            { value: t('student'), label: t('student') },
            { value: t('Beginner'), label: t('Beginner') },
            { value: t('professional (not manager)'), label: t('professional (not manager)') },
            { value: t('manager'), label:t('manager')},
            { value: t('Senior Management (Executive Vice President)'), label: t('Senior Management (Executive Vice President)') }
        ]
        const optionsAge = [
            { value: t('18 to 25'), label: t('18 to 25') },
            { value: t('25 to 30'), label: t('25 to 30') },
            { value: t('30 to over'), label: t('30 to over') },
            { value: t('less than 18'), label: t('less than 18') },
        ]
        return (
            <div>
                {!this.state.successSubmit ?
                    <form onSubmit={(e) => e.preventDefault()}>
                        <input
                            type="text"
                            name="name"
                            placeholder={t('yourName')}
                            value={this.state.name}
                            onSelectCapture={(e) => this.handleChangeGeneralInputs(e, 'name')}
                            onChange={(e) => this.handleChangeGeneralInputs(e, 'name')}
                        ></input>
                        <span className="err">{this.state.errors.name}</span>
                        <input
                            type="email"
                            name="email"
                            placeholder={t('Email')}
                            value={this.state.email}
                            onSelectCapture={(e) => this.handleChangeGeneralInputs(e, 'email')}
                            onChange={(e) => this.handleChangeGeneralInputs(e, 'email')}
                        ></input>
                        <span className="err">{this.state.errors.email}</span>
                        <input
                            type="tel"
                            autoComplete="tel"
                            name="phone"
                            className="input-pillars form-control"
                            id="phone"
                            placeholder={t('Phone')}
                            value={this.state.phone}
                            onChange={this.handleChange}
                        ></input>
                        <p id="valid-msg"
                              className="hide valid"> {t('✓ Valid')}</p>
                        <span id="error-msg" className="hide err"></span>

                        <span className="err">{this.state.phone_error}</span>
                        <Select className="SelectForm" name="experience" isSearchable={false} options={options}
                            placeholder={t('Experience')}
                            value={options.find(
                                (obj) => obj.value === this.state.experience
                            )}
                            onChange={(e) => this.handleSelect(e, 'experience')}
                        />
                        <span className="err">{this.state.errors.experience}</span>

                        <Select className="SelectForm" name="age" isSearchable={false} options={optionsAge}
                                placeholder={t('Age :')}
                                value={options.find(
                                    (obj) => obj.value === this.state.age
                                )}
                                onChange={(e) => this.handleSelect2(e, 'age')}
                        />
                        <span className="err">{this.state.errors.age}</span>
                        <div className="mt-4">
                            <button className="btn-form-edit" onClick={this.handleSubmit} disabled={this.state.disabled || this.state.disabledBtn}>
                                {t('Submit')}
                            </button>
                        </div>
                    </form> : <div className="d-flex flex-column justify-content-center align-items-center"
                        style={{ height: "100%" }}>
                        <Lottie
                            loop={false}
                            animationData={formSubmit}
                            play
                            style={{ width: 300 }}
                        />
                        <p className="paragraph-graph mb-5 fontSize">{t('contact')}</p>
                    </div>}
            </div>
        );
    }


};
export default withTranslation()(withRouter(Form));
