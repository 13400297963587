import React from "react";
import {Link, withRouter} from "react-router-dom";
import logo from "../images/Group 110.svg";
import i18n from "./i18n/i18n";
import icolangBlue from "../images/languageB.svg";

import {withTranslation} from "react-i18next";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
        top: elementPosition,
        left: 0,
        behavior: "smooth",
    });
};
const onRemoveClick = (e) => {
    document.getElementById("offcanvasNavbar").classList.remove("show");
    if (document.querySelector(".offcanvas-backdrop") !== null) {
        document.querySelector(".offcanvas-backdrop").classList.remove("show");
        let canvas = document.querySelector(".offcanvas-backdrop");
        canvas.style.display = "none";
    }
};

class NavBar extends React.Component {
    state = {
        navbarScroll: false,
        country:
            localStorage.getItem("i18nextLng") === "en" ||
            localStorage.getItem("i18nextLng") === "en-US"
                ? "English"
                : localStorage.getItem("i18nextLng") === "he"
                    ? "العربية"
                    : "English",
        lanug:
            localStorage.getItem("i18nextLng") === "us" ||
            localStorage.getItem("i18nextLng") === "en-US"
                ? "us"
                : localStorage.getItem("i18nextLng") === "he"
                    ? "eg"
                    : "us",
        // lanug: cookies.get('lanug') ? cookies.get('lanug') : 'us',
        direction: cookies.get("direction") ? cookies.get("direction") : "ltr",
    };

    componentDidMount() {

    }

    changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    render() {
        let {t} = this.props;
        return (

            <>
                <nav className="navbar navbar-dark navbar-expand-lg  fixed-top">
                    <div className="container">
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasNavbar"
                            aria-controls="offcanvasNavbar"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div
                            className="offcanvas offcanvas-end"
                            data-bs-scroll="true"
                            tabIndex="-1"
                            id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel"
                        >
                            <div className="offcanvas-header">
                                <button
                                    type="button"
                                    className="btn-close text-reset"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                ></button>

                            </div>
                            <div className="offcanvas-body">
                                <ul className="navbar-nav justify-content-between flex-grow-1 pe-3">
                                    <li className="nav-item dropdown">
                                    <img src={logo}/>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle"  id="navbarScrollingDropdown"
                                           role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {cookies.get('direction') === "ltr" ? "English" : "العربية"}
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                                            <li style={{borderBottom: "2px solid #007AA0 "}}><a
                                                className="dropdown-item" onClick={(e) => {
                                                if (cookies.get('direction') === "rtl") {
                                                    this.setState({
                                                        lanug: "en",

                                                    })
                                                    localStorage.setItem('direction', "ltr")
                                                    cookies.set('lanug', 'en')
                                                    cookies.set('direction', 'ltr')

                                                    // setInterval(() => {
                                                    window.location.reload(false);
                                                    // }, 100);
                                                }

                                            }

                                            }>English</a></li>
                                            <li><a className="dropdown-item" onClick={(e) => {
                                                if (cookies.get('direction') === "ltr") {
                                                    this.setState({
                                                        lanug: "eg",
                                                    })
                                                    localStorage.setItem('direction', "rtl")
                                                    cookies.set('lanug', 'eg')
                                                    cookies.set('direction', 'rtl')

                                                    // setInterval(() => {
                                                    window.location.reload(false);
                                                    // }, 100);
                                                }

                                            }

                                            }>العربية</a></li>
                                        </ul>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </>
        );
    }
}

export default withTranslation()(withRouter(NavBar));
