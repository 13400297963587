import React, {Component} from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "@fontsource/cairo";
import {history} from "./history";
import {Route, Router, Switch} from "react-router-dom";
// import About from "./components/About/about";
import What from "./components/What";
import NotFound from "./components/Error404";
// import Form from "./components/Form/form";
import Cookies from "universal-cookie";
import {useTranslation} from "react-i18next";
import './components/i18n/i18n';
import Navbar from "./components/Navbar";
const cookies = new Cookies();
let dir = cookies.get("direction")
    ? cookies.get("direction")
    : "rtl";
if (dir === "ltr") {
    import('./components/Form/style.css')
} else {
    import('./components/Form/styleRtl.css')
}
const App = () => {
    const {i18n} = useTranslation();
    document.body.dir = i18n.dir();
    return (
        <>
            <Router history={history}>
                <Switch>
                    <Route exact path="/:id" component={What}/>
                    <Route component={NotFound}/>
                </Switch>
            </Router>

        </>
    );
}

export default App;
