import React from "react";
import Img404 from "../images/404.png";
import ImgNotFound from "../images/notfound.png";
import {Link} from "react-router-dom";

class NotFound extends React.Component {
    // state = {
    //     hide: false
    // };
    //
    // hide = () => {
    //     this.setState({hide: true})
    // }

    render() {
        const {t} = this.props;
        return (
            <>
                <div className="container-fluid">
                    <div className="row py-5 Page404 mt-5">
                        <div className="col-lg-6 mt-5">
                            <div className="my-5 px-5 text-center mt-5">
                                <div className="div404 mt-5">
                                <img src={Img404} alt="404 not found" className="Img404 mt-5"/>
                                <p className="NotfoundTxt my-5 "> PAGE NOT FOUND</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="my-5 px-5 text-center">
                                <img src={ImgNotFound} alt="404 not found" className="Img404"/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default (NotFound);
