import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./style.css";
import { Button, Steps } from "antd";
import Form from "../Form/form";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import {
  UserOutlined,
  SolutionOutlined,
  // SmileOutlined,
} from "@ant-design/icons";
import {withRouter} from "react-router-dom";
import Navbar from "../Navbar";
// import myImg from "../../images/default.png";
import Cookies from "universal-cookie";
import {withTranslation} from "react-i18next";

const cookies = new Cookies();
const App = (props) => {
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(true);
  let lang = ''
  if(cookies.get('direction') === "ltr"){
  lang= "en"
  }else{
    lang="ar"
  }

  const [generalInformation, setGeneralInformation] = useState({});
  useEffect(() => {
    axios
    .get(process.env.REACT_APP_API_URL, {
      params: {
          url: `api/method/variiance.vol.api.v2.volsme.get_volsme_config?volsme_config_id=${props.match.params.id}&lang=${lang}`
      }
  }).then((response) => {
        setGeneralInformation(response.data.data);
        setLoading(false)

      });
  }, []);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const { Step } = Steps;
  const steps = [
    {
      title: "Start",
      content: (
        <>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <img src={generalInformation.image} alt="My Img" className="edit-img" />
              </div>
              <div className="col-md-12">
                <h1>  {props.t('interest')} {'"'+generalInformation.title+'"'}{props.t('?')}   </h1>
                <p className="fontSize">{props.t('fill')}</p>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Login",
      content: <Form />,
    },
    // {
    //   title: "End",
    //   content: <div></div>,
    //   icon: <SmileOutlined />,
    // },
  ];

  return (
    <>
      <Navbar/>
      {loading ?
          <div className="container text-center" style={{ marginTop: "400px" }}>
          <ClipLoader color="orange" size={20} />
          </div>
          :
          <div className="container" style={{ marginTop: "150px" }}>
        <Steps className="test" current={current} direction="horizontal" responsive={false}>
          {steps.map((item) => (
              <Step key={item.title} title={item.icon} />
          ))}
        </Steps>
        <div className="steps-content ">{steps[current].content}</div>
            <div className="steps-action">
              <div className="nextCenter">
          {current < steps.length - 1 && (
            <button className="btn-form-edit" onClick={() => next()}>
              {props.t('start')}
            </button>
          )}
              </div>
          {/* {current === steps.length - 1 && (
            <Button
              className="edit-btn"
              onClick={() => message.success("Processing complete!")}
            >
              Done
            </Button>
          )} */}
          {current > 0 && (
            <button className="btn-form-edit" style={{
            margin: "0 8px",
          }} onClick={() => prev()}>
              {props.t('back')}
            </button>
          )}
        </div>
      </div>}
    </>
  );
};

export default withTranslation()(withRouter(App));
